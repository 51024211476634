import React, { PropsWithChildren } from 'react';
import { Center, Container } from '@fiverr-private/layout_components';
import * as classes from './IdentificationFormWrapper.ve.css';

export const IdentificationFormWrapper = ({ children }: PropsWithChildren<unknown>) => (
  <Center paddingY={{ default: '0', md: '10' }}>
    <Container
      className={classes.formBoxShadow}
      height="645px"
      width={{ default: '100%', md: '900px' }}
      maxWidth="100%"
      backgroundColor="white"
      borderRadius="xl"
      overflow="hidden"
    >
      {children}
    </Container>
  </Center>
);
