import React from 'react';
import { StandaloneIdentificationForm } from '@fiverr-private/identification_business';
import { SigningMode } from '../../../types';
import { IdentificationFormWrapper } from '../shared/IdentificationFormWrapper';

interface BusinessIdentificationProps {
  mode: SigningMode;
}

export const BusinessIdentification = ({ mode }: BusinessIdentificationProps) => (
  <IdentificationFormWrapper>
    <StandaloneIdentificationForm mode={mode} source="identification_perseus" />
  </IdentificationFormWrapper>
);
